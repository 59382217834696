import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { SharedDataService } from '../services/shared.data.service';

declare var $: any;

@Injectable({ providedIn: 'root' })

export class RealTimeNotificationService {

  private proxy: any;
  private connection: any;

  // Update Daly usings every 5 seconds
  private isUpdateScheduled: boolean;

  constructor(private sharedDataService: SharedDataService) {
    
  }

  public initialize = () => {
    this.isUpdateScheduled = false;
    // create hub connection  
    this.connection = $.hubConnection(environment.apiRoot);
    // create new proxy as name already given in top  
    this.proxy = this.connection.createHubProxy('NotificationHub');
    // register on server events  
    this.registerOnServerEvents();
    // call the connecion start method to start the connection to send and receive events.  
    this.startConnection();
  }
  // check in the browser console for either signalr connected or not  
  private startConnection(): void {
    this.connection.start().done((data: any) => {
      console.log('Now connected ' + data.transport.name + ', connection ID= ' + data.id);
    }).fail((error: any) => {
      console.log('Could not connect ' + error);
    });
  }

  private registerOnServerEvents(): void {
    this.proxy.on('BroadcastNewCalculatorUsing', (message: string) => {
      this.scheduleNewDailyUsingsUpdate();
      console.log('received in SignalRService: ' + message);
    });
  }

  /**
   * Schedule refresh daily usings after neotification recevied from server.
   * Refresh daily usings every 5 seconds if new calculator usings are there
   * */
  private scheduleNewDailyUsingsUpdate = () => {
    if (!this.isUpdateScheduled) {
      this.isUpdateScheduled = true;
      setTimeout(() => {
        this.sharedDataService.refreshDailyUsage();
        this.isUpdateScheduled = false;
      }, 5000)
    }
  }
}
