import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*'
  })
};

export const localStorageUser: string = "ApplicationLoggedUser"; 

@Injectable({ providedIn: 'root' })

export class AuthenticationService {
  constructor(private http: HttpClient, private router: Router) { }

  login = (username: string, password: string) => {

    var creds = 'username=' + username + '&password=' + password + "&grant_type=password" + "&credentials=true";
    let headers = new HttpHeaders();
    headers.append("Content-Type", 'application/x-www-form-urlencoded;charset=UTF-8');
    headers.append('Access-Control-Allow-Origin', 'GET, POST, DELETE, PUT');

    return this.http.post<any>(environment.apiRoot + 'Token', creds, { headers: headers })
      .pipe(map(getTokenResponse => {
        // login successful if there's a jwt token in the response
        if (getTokenResponse && getTokenResponse.access_token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem(localStorageUser, JSON.stringify(getTokenResponse));
        }

        return getTokenResponse;
      }));
  }

  logout = () => {
    // remove user from local storage to log user out
    localStorage.removeItem(localStorageUser);
    this.router.navigate(['/login']);
  }

  get isUserAdministrator() {
    var currentLoggedUser = JSON.parse(localStorage.getItem(localStorageUser));
    return !!currentLoggedUser && !!currentLoggedUser.userRoles && currentLoggedUser.userRoles.indexOf('Administrator') != -1;
  }

  get loggedAccountUserName() {
    var currentLoggedUser = JSON.parse(localStorage.getItem(localStorageUser));
    return currentLoggedUser.userName;
  }
}
