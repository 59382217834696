import { Component, OnInit, Input, ViewEncapsulation  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CalculatorDataService } from '../../services/calculator-data.service';
import { SharedDataService } from '../../services/shared.data.service';
import { ExportDataToCSVService } from '../../services/export.data.to.csv.service';
import { CalculationDetailsByCompany } from '../../models/calculator-data.models';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

@Component({
  templateUrl: 'companycalcdetails.component.html',
  styleUrls: ['./../../app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CompanyCalcDetailsComponent implements OnInit {

  calculationDetailsByCompanies: CalculationDetailsByCompany[] = [];
  companyName: string;
  expandedItems: number[] = [];

  pageSize: number = 10;
  currentPage: number = 0;
  startItem: number = 0;
  endItem: number = 10;

  //sorting
  key: string = 'lastName'; //set default
  reverse: boolean = false;

  constructor(private route: ActivatedRoute,
    private calculatorDataService: CalculatorDataService,
    private sharedDataService: SharedDataService,
    private exportDataToCSVService: ExportDataToCSVService) {
  }

  ngOnInit(): void {
    this.companyName = this.route.snapshot.queryParamMap.get('companyName');

    this.calculatorDataService.getCalculationDetailsByCompany(this.sharedDataService.filterDateForCalcDataByCompaniesFrom,
      this.sharedDataService.filterDateForCalcDataByCompaniesTo, this.companyName).subscribe(
      (response: CalculationDetailsByCompany[]) => {
        this.calculationDetailsByCompanies = response;
      },
        (error) => { console.error(error); }
      );
  }

  sort = (key) => {
    this.key = key;
    this.reverse = !this.reverse;

    let up: number = 1;
    let down: number = -1;
    if (this.reverse) {
      up = -1;
      down = 1;
    }

    switch (this.key) {
      case 'firstName':
        this.calculationDetailsByCompanies = this.calculationDetailsByCompanies.sort((a, b) => a.userInformation.firstName > b.userInformation.firstName ? up : a.userInformation.firstName === b.userInformation.firstName ? 0 : down);
        break;
      case 'lastName':
        this.calculationDetailsByCompanies = this.calculationDetailsByCompanies.sort((a, b) => a.userInformation.lastName > b.userInformation.firstName ? up : a.userInformation.lastName === b.userInformation.lastName ? 0 : down);
        break;
      case 'email':
        this.calculationDetailsByCompanies = this.calculationDetailsByCompanies.sort((a, b) => a.userInformation.lastName > b.userInformation.email ? up : a.userInformation.email === b.userInformation.email ? 0 : down);
        break;
      case 'totalCareerEventCosts':
        this.calculationDetailsByCompanies = this.calculationDetailsByCompanies.sort((a, b) => (a.calculationResults.withoutYelloData.totalCareerEventCosts - a.calculationResults.withYelloData.totalCareerEventCosts) > (b.calculationResults.withoutYelloData.totalCareerEventCosts - b.calculationResults.withYelloData.totalCareerEventCosts) ? up : down);
        break;
      case 'numOfAnnualEvents':
        this.calculationDetailsByCompanies = this.calculationDetailsByCompanies.sort((a, b) => a.numOfAnnualEvents > b.numOfAnnualEvents ? up : down);
        break;
      case 'calculationsDate':
        this.calculationDetailsByCompanies = this.calculationDetailsByCompanies.sort((a, b) => a.calculationsDate > b.calculationsDate ? up : down);
    }
  }

  get calculationDetailsByCompaniesFiltered() {
    return this.calculationDetailsByCompanies.slice(this.startItem, this.endItem)
  }

  get filterDateForCalcDataByCompaniesFrom() {
    return this.sharedDataService.filterDateForCalcDataByCompaniesFrom;
  }

  get filterDateForCalcDataByCompaniesTo() {
    return this.sharedDataService.filterDateForCalcDataByCompaniesTo;
  }

  pageChanged(event: PageChangedEvent): void {
    this.startItem = (event.page - 1) * event.itemsPerPage;
    this.endItem = event.page * event.itemsPerPage;
  }

  isItemCollapsed = (index: number): boolean => {
    return this.expandedItems.indexOf(index) == -1;
  }

  colapseItem = (index: number): void => {
    if (this.isItemCollapsed(index)) {
      this.expandedItems.push(index);
    }
    else {
      this.expandedItems = this.arrayRemove(this.expandedItems, index);
    }
  }

  arrayRemove = (arr, value) => {
    return arr.filter( (ele) => {
      return ele != value;
    })
  }

  exportDataToExcel = (calculationDetailsByCompanies: CalculationDetailsByCompany[]) => {
    this.exportDataToCSVService.exportCalculationDetailsByCompanies(calculationDetailsByCompanies, this.companyName, this.sharedDataService.filterDatesForCalcDataByCompanies);
  }

  getFormatedDate = (fromDate: Date): string => {
    let date = new Date(fromDate);
    return (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
  }

  getFormatedValue = (value: number) => {
    return this.sharedDataService.getFormatedAndRoundedValue(value);
  } 
}
