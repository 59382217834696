import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable({
  providedIn: 'root',
})

export class CalculatorDataService {

  apiRoot: string = environment.apiRoot + 'CalculatorDetails/';

  constructor(private http: HttpClient) {
  }

  /*
   * Public Exposed Services
   **/

  public getDailyUsings = (requestedDate: Date) => {
    return this.http.get(this.apiRoot + 'GetDalyUsage?requestedDate=' + this.getFormatedDate(requestedDate), httpOptions);
  }

  public getAverageSavings = (fromDate: Date, toDate: Date) => {
    return this.http.get(this.apiRoot + 'GetAverageSavings?dateFrom=' + this.getFormatedDate(fromDate) + '&dateTo=' + this.getFormatedDate(toDate), httpOptions);
  }

  public getCompaniesBasicCalcData = (fromDate: Date, toDate: Date) => {
    return this.http.get(this.apiRoot + 'GetCompaniesBasicCalcData?dateFrom=' + this.getFormatedDate(fromDate) + '&dateTo=' + this.getFormatedDate(toDate), httpOptions);
  }

  public getCalculationDetailsByCompany = (fromDate: Date, toDate: Date, companyName: string) => {
    return this.http.get(this.apiRoot + 'GetCalculationDetailsByCompany?dateFrom=' + this.getFormatedDate(fromDate) + '&dateTo=' + this.getFormatedDate(toDate) + '&companyName=' + companyName, httpOptions);
  }
  /*
   * End Of Public Exposed Services
   **/
  
  /*
   * Private Helpers 
   **/

  private getFormatedDate = (date: Date): string => {
    return (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
  }


  /*
   * End Private Helpers
   **/
}
