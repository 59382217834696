import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';

@Injectable({ providedIn: 'root' })

export class AlertNotificationService {

  constructor(private notifierService: NotifierService) {
  }

  public hideNewest = (): void => {
    this.notifierService.hideNewest();
  }
  /**
   * API: Hide the oldest notification
   */
  public hideOldest = (): void => {
    this.notifierService.hideOldest();
  }
  /**
   * API: Hide all notifications at once
   */
  public hideAll = (): void => {
    this.notifierService.hideAll();
  }
  /**
   * API: Shortcut for showing a new notification
   *
   * @param type             Type of the notification
   * @param message          Message of the notification
   * @param [notificationId] Unique ID for the notification (optional)
   */
  private notify = (type: string, message: string, notificationId?: string): void => {
    this.notifierService.notify(type, message);
  }

  successAlert = (message: string) => {
    this.notify("success", message);
  }

  errorAlert = (message: string) => {
    this.notify("error", message);
  }

  infoAlert = (message: string) => {
    this.notify("info", message);
  }

  warningAlert = (message: string) => {
    this.notify("warning", message);
  }

  defaultAlert = (message: string) => {
    this.notify("default", message);
  }
}
