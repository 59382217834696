import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ApplicationUser, ChangePasswordModel, SetPasswordModel } from '../models/useraccounts.models';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable({ providedIn: 'root' })

export class UserAccountService {
  private readonly apiRoot: string = `${environment.apiRoot}api/Account`; 
  constructor(private http: HttpClient) { }

  public getUserAccounts = (itemsPerPage: number, pageIndex: number) => {
    return this.http.get(`${this.apiRoot}/GetUsers?itemsPerPage=${itemsPerPage}&pageIndex=${pageIndex}`, httpOptions);
  }

  public searchUserAccounts = (email: string, itemsPerPage: number, pageIndex: number) => {
    return this.http.get(`${this.apiRoot}/SearchUsers?email${email}&itemsPerPage=${itemsPerPage}&pageIndex=${pageIndex}`, httpOptions);
  }

  public registerNewUserAccount = (userAccount: ApplicationUser) => {
    return this.http.post(`${this.apiRoot}/Register`, userAccount, httpOptions);
  }

  public getUser = (userName: string) => {
    return this.http.get(`${this.apiRoot}/GetUser?userName=${userName}`, httpOptions); 
  }

  public updateUser = (userAccount: ApplicationUser, updatePassword: boolean) => {
    return this.http.post(`${this.apiRoot}/UpdateUser?updatePassword=${updatePassword}`, userAccount, httpOptions);
  }

  public getMyInfo = () => {
    return this.http.get(`${this.apiRoot}/GetMyInfo`, httpOptions); 
  }

  public updateMyInfo = (userAccount: ApplicationUser, updatePassword: boolean) => {
    return this.http.post(`${this.apiRoot}/UpdateMyInfo?updatePassword=${updatePassword}`, userAccount, httpOptions);
  }

  public changePassword = (changePasswordmodel: ChangePasswordModel) => {
    return this.http.post(`${this.apiRoot}/changePassword`, changePasswordmodel, httpOptions);
  }

  public setPassword = (setPasswordmodel: SetPasswordModel) => {
    return this.http.post(`${this.apiRoot}/setPassword`, setPasswordmodel, httpOptions);
  }
}
