export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: any;
  title?: boolean;
  children?: any;
  variant?: string;
  attributes?: object;
  divider?: boolean;
  class?: string;
}

export const navItems: NavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
  },
  {
    title: true,
    name: 'Calculator'
  },
  {
    name: 'Calculator Usage',
    url: '/dashboard',
    icon: 'icon-drop'
  },
  //{
  //  name: 'By company',
  //  url: '/bycompany',
  //  icon: 'icon-pencil'
  //},
  {
    name: 'Comprehensive',
    url: '/comprehensive',
    icon: 'icon-puzzle'
  }
];
