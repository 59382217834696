export interface ApplicationUser {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  companyName: string;
  jobTitle: string;
  isAdministrator: boolean;
}

export interface UserAccountsResponse {
  userAccounts: ApplicationUser[];
  totalNumberOfUsers: number;
}

export interface ChangePasswordModel {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface SetPasswordModel {
  email: string;
  newPassword: string;
  confirmPassword: string;
}

export enum AddOrUpdateAction {
  Add,
  Update,
  UpdateMyAccount
}

export enum PasswordAction {
  Set,
  Update
}


