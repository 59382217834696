import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChangePasswordModel, SetPasswordModel, PasswordAction } from '../../models/useraccounts.models';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { UserAccountService } from '../../services/useraccount.service';
import { AuthenticationService } from '../../services/authentication.service';
import { AlertNotificationService } from '../../notification/alertnotification.service';

@Component({
  templateUrl: 'changepassword.component.html',
  styleUrls: ['./../../app.component.css'],
})

export class ChangePasswordComponent implements OnInit {

  ngOnInit(): void {
    this.action = PasswordAction[this.route.snapshot.queryParamMap.get('action')];
    var email = this.route.snapshot.queryParamMap.get('email');
    this.changePasswordForm = this.formBuilder.group({
      oldPassword: ['', this.validateOldPassword],
      newPassword: [email, this.validatePassword],
      email: ['', this.validateEmail],
      confirmPassword: ['', [Validators.required, this.validateNewPassword]],
    });
  }

  action: PasswordAction = PasswordAction.Set;
  changePasswordForm: FormGroup;
  username: string;
  submitted: boolean = false;

  constructor(private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private userAccountService: UserAccountService,
    private alertNotificationService: AlertNotificationService
  ) {
  }

  updatePassword = (): void => {

    if (this.changePasswordForm.invalid) {
      this.submitted = true;
      return;
    }

    if (this.action == PasswordAction.Update) {

      var changePasswordRequest = this.changePasswordForm.value as ChangePasswordModel;

      this.userAccountService.changePassword(changePasswordRequest)
        .subscribe(
          result => this.alertNotificationService.successAlert("Password successfully changed!"),
          error => this.alertNotificationService.errorAlert(error)
        );
    }
    else {
      var setPasswordRequest = this.changePasswordForm.value as SetPasswordModel;

      this.userAccountService.setPassword(setPasswordRequest)
        .subscribe(
          result => this.alertNotificationService.successAlert("Password successfully set!"),
          error => this.alertNotificationService.errorAlert(error)
        );

    }
  }

  get isUpdate() {
    return this.action == PasswordAction.Update;
  }

  get changePasswordFormControls() {
    return this.changePasswordForm.controls;
  }

  get title() {
    return this.action == PasswordAction.Update ? "Update Password" : "Set Password";
  }

  validatePassword = (control: AbstractControl): { [key: string]: any } => {
    if (String(control.value).length < 6) {
      return { 'notMatch': 'Password must be min 6 characters length!' };
    }
    if (!(/[a-z]+/.test(control.value))) {
      return { 'notMatch': 'Missing lowecase charachter!' };
    }

    if (!(/[A-Z]+/.test(control.value))) {
      return { 'notMatch': 'Missing uppercase charachter!' };
    }

    if (!(/[1-9]+/.test(control.value))) {
      return { 'notMatch': 'Missing number as charachter!' };
    }

    if (!(/[!@#$%^&*(),.?":{}|<>]/.test(control.value))) {
      return { 'notMatch': 'Missing special character!' };
    }
    return null;
  }

  validateNewPassword = (control: AbstractControl): { [key: string]: any } => {
    if (!!this.changePasswordForm && control.value !== this.changePasswordForm.controls.newPassword.value) {
      return { 'notMatch': 'Password does not match!' };
    }
    return null;
  }

  validateOldPassword = (control: AbstractControl): { [key: string]: any } => {
    if (this.isUpdate && !control.value) {
      return { 'notMatch': 'Password is required!' };
    }
    return null;
  }

  validateEmail = (control: AbstractControl): { [key: string]: any } => {
    if (!this.isUpdate && !control.value) {
      return { 'notMatch': 'Email is required!' };
    }
    return null;
  }
}
