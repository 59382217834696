import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { AddOrUpdateAccountComponent } from './views/addorupdateaccount/addorupdateaccount.component';
import { CompanyCalcDetailsComponent } from './views/companycalcdetails/companycalcdetails.component';
import { ComprehensiveComponent } from './views/comprehensive/comprehensive.component';
import { ManageUsersComponent } from './views/manageusers/manageusers.component';
import { ChangePasswordComponent } from './views/changepassword/changepassword.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: './views/dashboard/dashboard.module#DashboardModule',
      },
      {
        path: 'companycalcdetails',
        component: CompanyCalcDetailsComponent,
      },
      {
        path: 'comprehensive',
        component: ComprehensiveComponent
      },
      {
        path: 'manageusers',
        component: ManageUsersComponent
      },
      {
        path: 'addOrUpdateAccount',
        component: AddOrUpdateAccountComponent,
      },
      {
        path: 'updateMyInfo',
        component: AddOrUpdateAccountComponent,
      },
      {
        path: 'changePassword',
        component: ChangePasswordComponent,
      },
      {
        path: 'setPassword',
        component: ChangePasswordComponent,
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
