import { Component, OnInit } from '@angular/core';
import { UserAccountService } from '../../services/useraccount.service';
import { ApplicationUser, UserAccountsResponse } from '../../models/useraccounts.models';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';


@Component({
  templateUrl: 'manageusers.component.html'
})

export class ManageUsersComponent implements OnInit {

  users: ApplicationUser[] = [];
  totalNumberOfUsers: number;

  constructor(private userAccountService: UserAccountService) { }

  ngOnInit(): void {
    this.getUsers(10, 1);
  }

  getUsers = (itemsPerPage: number, pageIndex: number) => {
    this.userAccountService.getUserAccounts(itemsPerPage, pageIndex)
      .subscribe(
      (result: UserAccountsResponse) => {
        this.users = result.userAccounts;
        this.totalNumberOfUsers = result.totalNumberOfUsers;
      },
        error => console.log(error));
  }

  pageChanged = (event: PageChangedEvent): void => {
    this.getUsers(event.itemsPerPage, event.page);
  }
  
}
