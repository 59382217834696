import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CalculatorDataService } from '../services/calculator-data.service';
import { DailyUsageResponse, CompanyCalcData } from '../models/calculator-data.models';

@Injectable({
  providedIn: 'root',
})

export class SharedDataService {

  _dailyUsage: DailyUsageResponse;;

  _averageSavings: number;
  _filterDatesForAvgSavings: Date[];

  _calcDetailsByCompanies: CompanyCalcData[] = [];
  _filterDatesForCalcDataByCompanies: Date[];

  constructor(private calculatorDataService: CalculatorDataService) {
    this.filterDatesForCalcDataByCompanies = [this.getLastWeek(), new Date()];
    this.filterDatesForAvgSavings = this.filterDatesForCalcDataByCompanies;
  }

  pullSharedData = () => {
    this.refreshDailyUsage();
    this.refreshAverageSavings();
    this.refreshCalcDetails();
  }

  /******* Average Savings **************/

  refreshAverageSavings = () => {
    this.calculatorDataService.getAverageSavings(this._filterDatesForAvgSavings[0], this._filterDatesForAvgSavings[1]).subscribe(
      (response: number) => { this._averageSavings = response; },
      (error) => {
        console.error(error);
      });
  }

  get averageSavings(): number {
    return this._averageSavings;
  }

  /******* Average Savings end **************/


  /************ Calc details By Company ***********/

  refreshCalcDetails = () => {
    this.calculatorDataService.getCompaniesBasicCalcData(this.filterDateForCalcDataByCompaniesFrom, this.filterDateForCalcDataByCompaniesTo).subscribe(
      (response: CompanyCalcData[]) => { this._calcDetailsByCompanies = response; },
      (error) => { console.error(error); }
    );
  }

  get calcDetailsByCompanies() {
    return this._calcDetailsByCompanies;
  }

  sortCalDetailsByCompaniesBy = (key: string, reverse: boolean) => {

    let up: number = 1;
    let down: number = -1;
    if (reverse) {
      up = -1;
      down = 1;
    }

    switch (key) {
      case 'companyName':
        this._calcDetailsByCompanies = this.calcDetailsByCompanies.sort((a, b) => a.companyName.toLocaleLowerCase() > b.companyName.toLocaleLowerCase() ? up : a.companyName.toLocaleLowerCase() === b.companyName.toLocaleLowerCase() ? 0 : down);
        break;
      case 'numberOfCalculations':
        this._calcDetailsByCompanies = this.calcDetailsByCompanies.sort((a, b) => a.numberOfCalculations > b.numberOfCalculations ? up : a.numberOfCalculations === b.numberOfCalculations ? 0 : down);
        break;
      case 'averageSavings':
        this._calcDetailsByCompanies = this.calcDetailsByCompanies.sort((a, b) => a.averageSavings > b.averageSavings ? up : a.averageSavings === b.averageSavings ? 0 : down);
        break;
      case 'numberOfUsers':
        this._calcDetailsByCompanies = this.calcDetailsByCompanies.sort((a, b) => a.numberOfDiferrentUser > b.numberOfDiferrentUser ? up : a.numberOfDiferrentUser === b.numberOfDiferrentUser ? 0 : down);
        break;
    }
  } 
  

  /************ Calc details By Company end ***********/




  /************* Daily usage *****************/

  get dailyUsage() {
    return this._dailyUsage;
  }
  
  refreshDailyUsage = (): void => {
    this.calculatorDataService.getDailyUsings(new Date()).subscribe(
      (response: DailyUsageResponse) => { this._dailyUsage = response },
      (error) => { console.error(error); }
    );
  }

  /************* Daily usage end *****************/


  /************* Average savings Data *****************/

  get filterDatesForAvgSavingsFrom() {
    return this._filterDatesForAvgSavings[0];
  }

  get filterDatesForAvgSavingsTo() {
    return this._filterDatesForAvgSavings[1];
  }

  set filterDatesForAvgSavings(dates: Date[]) {
    this._filterDatesForAvgSavings = dates;
  }

  get filterDatesForAvgSavings() {
    return this._filterDatesForAvgSavings;
  }

  /************* Average savings Data end *****************/


  /************* Calculator data by Companies *****************/

  get filterDateForCalcDataByCompaniesFrom() {
    return this._filterDatesForCalcDataByCompanies[0];
  }

  get filterDateForCalcDataByCompaniesTo() {
    return this._filterDatesForCalcDataByCompanies[1];
  }

  set filterDatesForCalcDataByCompanies(dates: Date[]) {
    this._filterDatesForCalcDataByCompanies = dates;
  }

  get filterDatesForCalcDataByCompanies() {
    return this._filterDatesForCalcDataByCompanies ;
  }

  /************* Calculator data by Companies end *****************/

  /************* Helper methods *****************/

  getLastWeek = (): Date => {
    let today = new Date();
    let lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    return lastWeek;
  }
  /************* Helper methods end *****************/

  getFormatedAndRoundedValue = (value: number): string => {
    if (!value) {
      return "0";
    }

    var result: string = Math.round(value).toString();
    return result.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

}
