import { Injectable } from '@angular/core';
import { CalculationDetailsByCompany } from './../models/calculator-data.models';
import { WorkBook, WorkSheet, utils, JSON2SheetOpts, writeFile } from 'xlsx';
import { throwError } from 'rxjs';

const EXCEL_EXTENSION = '.xlsx';

interface CalculationDetailsByCompaniesRow {
  firstName: string;
  lastName: string;
  companyName: string;
  country: string;
  email: string;
  jobTitle: string;
  calculationsDate: string;
  numOfAnnualEvents: number;
  recruiterTimeToAcquireCandidateDataWithYello: string;
  recruiterTimeToAcquireCandidateDataWithoutYello: string;
  recruiterCostToAcquireCandidateDataWithYello: number;
  recruiterCostToAcquireCandidateDataWithoutYello: number;
  postEventFollowUpCostWithYello: number;
  postEventFollowUpCostWithoutYello: number;
  postEventFollowUpHoursWithYello: string;
  postEventFollowUpHoursWithoutYello: string;
  printedCollateralCostsWithYello: number;
  printedCollateralCostsWithoutYello: number;
  totalCareerEventCostsWithYello: number;
  totalCareerEventCostsWithoutYello: number;
};

@Injectable({
  providedIn: 'root',
})

export class ExportDataToCSVService {

  constructor() {
  }

  private exportAsExcelFile(json: any[], excelFileName: string, sheetName: string, headerNames?: string[], sheetOptions?: JSON2SheetOpts): void {

    //const worksheet: WorkSheet = utils.json_to_sheet<CalculationDetailsByCompaniesRow>(json);
    //console.log('worksheet', worksheet);
    //const workbook: WorkBook = { Sheets: { 'data': worksheet }, SheetNames: sheetNames };
    //const excelBuffer: any = write(workbook, { bookType: 'xlsx', type: 'array' });
    ////const excelBuffer: any = write(workbook, { bookType: 'xlsx', type: 'buffer' });
    //this.saveAsExcelFile(excelBuffer, excelFileName);

    const worksheet: WorkSheet = utils.json_to_sheet<CalculationDetailsByCompaniesRow>(json, sheetOptions);

    if (!!headerNames) {
      this.updateHeaderNames(worksheet, headerNames);
    }

    const workbook: WorkBook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, sheetName);
    writeFile(workbook, excelFileName + EXCEL_EXTENSION);

  }
  
  public exportCalculationDetailsByCompanies = (calculationDetailsByCompanies: CalculationDetailsByCompany[], companyName: string, dateRange: Date[]) => {
    let data = this.prepareCalculationDetailsByCompaniesToExportToCSV(calculationDetailsByCompanies);
    let filename: string = 'Calculation Details for Company: ' + companyName;
    let headerNames = ['User First Name',
      'User Last Name',
      'Company',
      'Country',
      'Email',
      'Job Title',
      'Calculation Date',
      '# Of Annual Recruitments Events',
      'Recruiter Time to Acquire Candidate Data With Yello',
      'Recruiter Time to Acquire Candidate Data Without Yello',
      'Recruiter Cost to Acquire Candidate Data With Yello',
      'Recruiter Cost to Acquire Candidate Data Without Yello',
      'Post-Event-Follow-up Cost With Yello ',
      'Post-Event-Follow-up Cost Without Yello',
      'Post-Event-Follow-up Hours With Yello',
      'Post-Event-Follow-up Hours Without Yello',
      'Printed Collateral Cost With Yello',
      'Printed Collateral Cost Without Yello',
      'Total Recruitment Event Costs With Yello',
      'Total Recruitment Event Costs Without Yello'
    ];
    let sheetOptions: JSON2SheetOpts = {
      dateNF: 'mm"/"dd"/"yyyy',
      cellDates: true
    }

    this.exportAsExcelFile(data, filename, 'Calculation Details', headerNames, sheetOptions);
                
  }

  private updateHeaderNames = (ws: WorkSheet, headerNames: string[]) => {
    var range = utils.decode_range(ws['!ref']);

    if (range.e.c != (headerNames.length - 1)) {
      throwError('HeaderNames contain invalid number of columns!');
    }

    for (var C = range.s.r; C <= range.e.c; ++C) {
      var address = utils.encode_col(C) + "1"; // <-- first row, column number C
      if (!ws[address]) continue;
      
      ws[address].v = headerNames[C];
    }
  }


  private prepareCalculationDetailsByCompaniesToExportToCSV = (calculationDetailsByCompanies: CalculationDetailsByCompany[]): CalculationDetailsByCompaniesRow[] => {

    let result: CalculationDetailsByCompaniesRow[] = [];

    calculationDetailsByCompanies.forEach(item => {
      result.push({
        firstName: item.userInformation.firstName,
        lastName: item.userInformation.lastName,
        companyName: !!item.userInformation.companyName ? item.userInformation.companyName : '',
        country: !!item.userInformation.country ? item.userInformation.country : '',
        email: item.userInformation.email,
        jobTitle: !!item.userInformation.jobTitle ? item.userInformation.jobTitle : '',
        calculationsDate: this.getFormatedDate(item.calculationsDate),
        numOfAnnualEvents: item.numOfAnnualEvents,
        recruiterTimeToAcquireCandidateDataWithYello: item.calculationResults.withYelloData.recruiterTimeToAcquireCandidateData + ' Hours',
        recruiterTimeToAcquireCandidateDataWithoutYello: item.calculationResults.withoutYelloData.recruiterTimeToAcquireCandidateData + ' Hours',
        recruiterCostToAcquireCandidateDataWithYello: item.calculationResults.withYelloData.recruiterCostToAcquireCandidateData,
        recruiterCostToAcquireCandidateDataWithoutYello: item.calculationResults.withoutYelloData.recruiterCostToAcquireCandidateData,
        postEventFollowUpCostWithYello: item.calculationResults.withYelloData.postEventFollowUpCost,
        postEventFollowUpCostWithoutYello: item.calculationResults.withoutYelloData.postEventFollowUpCost,
        postEventFollowUpHoursWithYello: item.calculationResults.withYelloData.postEventFollowUpHours + ' Hours',
        postEventFollowUpHoursWithoutYello: item.calculationResults.withoutYelloData.postEventFollowUpHours + ' Hours',
        printedCollateralCostsWithYello: item.calculationResults.withYelloData.printedCollateralCosts,
        printedCollateralCostsWithoutYello: item.calculationResults.withoutYelloData.printedCollateralCosts,
        totalCareerEventCostsWithYello: item.calculationResults.withYelloData.totalCareerEventCosts,
        totalCareerEventCostsWithoutYello: item.calculationResults.withoutYelloData.totalCareerEventCosts,
      });
    });

    return result;
  }

  getFormatedDate = (fromDate: Date): string => {
    let date = new Date(fromDate);
    return (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
  }
}
