import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GetTokenResponse } from '../models/authentication.models';
import { localStorageUser } from '../services/authentication.service';

@Injectable()

export class JwtInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // add authorization header with jwt token if available
      let currentUser = JSON.parse(localStorage.getItem(localStorageUser)) as GetTokenResponse;
      if (currentUser && currentUser.access_token) {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${currentUser.access_token}`
                }
            });
        }

        return next.handle(request);
    }
}
