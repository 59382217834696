import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AddOrUpdateAction, ApplicationUser } from '../../models/useraccounts.models';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { UserAccountService } from '../../services/useraccount.service';
import { AuthenticationService } from '../../services/authentication.service';
import { AlertNotificationService } from '../../notification/alertnotification.service';

export const phoneRegEx = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

@Component({
  templateUrl: 'addorupdateaccount.component.html',
  styleUrls: ['./../../app.component.css'],
})

export class AddOrUpdateAccountComponent implements OnInit {
  private readonly passwordTemp: string = 'temporaryPassword';


  ngOnInit(): void {
    this.action = AddOrUpdateAction[this.route.snapshot.queryParamMap.get('action')];
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', this.validatePhone],
      companyName: [''],
      password: ['', this.validatePassword],
      jobTitle: [''],
      isAdministrator: [false]
    });

    if (this.action != AddOrUpdateAction.Add) {
      this.getUserAccountAndPopulateForm();
    }
  }

  registerForm: FormGroup;
  action: AddOrUpdateAction = AddOrUpdateAction.Add;
  updatePassword: boolean = false;
  submitted: boolean = false;

  constructor(private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private userAccountService: UserAccountService,
    private authenticationService: AuthenticationService,
    private alertNotificationService: AlertNotificationService
  ) {
    this.action = AddOrUpdateAction[this.route.snapshot.queryParamMap.get('action')];
  }

  createOrUpdateAccount = (): void => {

    if (this.registerForm.invalid) {
      this.submitted = true;
      return;
    }

    let userAccount: ApplicationUser = this.registerForm.value as ApplicationUser;

    switch (this.action) {
      case AddOrUpdateAction.Add:
        this.userAccountService.registerNewUserAccount(userAccount).subscribe(result => this.alertNotificationService.successAlert("User created successfully!"), error => this.alertNotificationService.errorAlert("An error occured!"));
        break;
      case AddOrUpdateAction.Update:
        this.userAccountService.updateUser(userAccount, this.updatePassword).subscribe(result => this.alertNotificationService.successAlert("User updated successfully!"), error => this.alertNotificationService.errorAlert("An error occured!"));
        break;
      case AddOrUpdateAction.UpdateMyAccount:
        this.userAccountService.updateMyInfo(userAccount, this.updatePassword).subscribe(result => this.alertNotificationService.successAlert("Update completed successfully!"), error => this.alertNotificationService.errorAlert("An error occured!"));
    }
  }

  getUserAccountAndPopulateForm = () => {
    let functionToCall;
    var username: string;
    switch (this.action) {
      case AddOrUpdateAction.Update:
        functionToCall = this.userAccountService.getUser;
        username = this.route.snapshot.queryParamMap.get('userName');
        break;
      case AddOrUpdateAction.UpdateMyAccount:
        functionToCall = this.userAccountService.getMyInfo;
        username = this.authenticationService.loggedAccountUserName;
        break;
    }

    return functionToCall(username)
      .subscribe(
        (result: ApplicationUser) => {
          this.registerForm = this.formBuilder.group({
            firstName: [result.firstName, Validators.required],
            lastName: [result.lastName, Validators.required],
            email: [result.email, [Validators.required, Validators.email]],
            phoneNumber: [result.phoneNumber],
            companyName: [result.companyName],
            password: [this.passwordTemp, this.validatePassword],
            jobTitle: [result.jobTitle],
            isAdministrator: [result.isAdministrator]
          });
        },
        (error) => { console.error(error); }
      );
  }

  get registerFormControls() {
    return this.registerForm.controls;
  }

  get buttontitle() {
    switch (this.action) {
      case AddOrUpdateAction.Add:
        return 'Create Account';
      case AddOrUpdateAction.Update:
        return 'Update Account';
      case AddOrUpdateAction.UpdateMyAccount:
        return 'Update My Account';
    }
  }

  get title() {
    return this.action == AddOrUpdateAction.Add ? 'Register' : 'Update';
  }

  get titlefooter() {
    switch (this.action) {
      case AddOrUpdateAction.Add:
        return 'Create new User Account';
      case AddOrUpdateAction.Update:
        return 'Update existing User Account';
      case AddOrUpdateAction.UpdateMyAccount:
        return 'Update My User Account';
    }
  }

  get showPassword() {
    return this.action == AddOrUpdateAction.Add;
  }

  get showAdministratorOption() {
    return this.action == AddOrUpdateAction.Add || this.action == AddOrUpdateAction.Update;
  }

  validatePassword = (control: AbstractControl): { [key: string]: any } => {
    if (this.showPassword) {
      if (String(control.value).length < 6) {
        return { 'notMatch': 'Password must be min 6 characters length!' };
      }
      if (!(/[a-z]+/.test(control.value))) {
        return { 'notMatch': 'Missing lowecase charachter!' };
      }

      if (!(/[A-Z]+/.test(control.value))) {
        return { 'notMatch': 'Missing uppercase charachter!' };
      }

      if (!(/[1-9]+/.test(control.value))) {
        return { 'notMatch': 'Missing number as charachter!' };
      }

      if (!(/[!@#$%^&*(),.?":{}|<>]/.test(control.value))) {
        return { 'notMatch': 'Missing special character!' };
      }
    }
    return null;
  }

  validatePhone = (control: AbstractControl): { [key: string]: any } => {
    if (!!control.value && !control.value.toString().match(phoneRegEx)) {
      return { 'notMatch': 'Phone format invalid!' };
    }
    return null;
  }
}
